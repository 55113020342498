<template>
  <v-card>
    <!-- HEADER TOOLBAR -->
    <v-overlay v-if="loading">
      <v-progress-circular
        :rotate="360"
        :size="100"
        :width="15"
        :value="progress"
      >
        {{ progressText }}
      </v-progress-circular>
    </v-overlay>
    <v-toolbar class="page-primary-card-header">
      <v-list-item dark>
        <v-list-item-avatar color="primary lighten-2">
          <v-icon>{{ card_header_props.header.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline">{{
            card_header_props.header.headLine
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
            card_header_props.header.subTitle
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-btn
        @click="
          card_header_props.helper.page_helper =
            !card_header_props.helper.page_helper
        "
        icon
        dark
      >
        <v-icon>info</v-icon>
      </v-btn>

      <v-dialog v-model="card_header_props.helper.page_helper" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title
            >{{ $t("_common.How_can_I_use_this_form") }}
          </v-card-title>

          <v-card-text>{{ $t("_create_content._info.content") }}</v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="card_header_props.helper.page_helper = false"
              >{{ $t("_common.OK") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <!-- HEADER TOOLBAR -->

    <!--    <v-app app-data>-->
    <v-container class="mx-0 col-12">
      <v-layout>
        <v-card class="col-12 m-0">
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-card flat xs12 width="100%" class="pt-2">
                <v-sheet class="px-4">
                  <v-text-field
                    v-model="search"
                    :label="this.$t('_create_content.Search_Topic')"
                    flat
                    hide-details
                    clearable
                    class="mt-0"
                    clear-icon="mdi-close-circle-outline"
                  ></v-text-field>
                </v-sheet>
                <v-card-text style="height: 464px; overflow: auto">
                  <v-treeview
                    :items="treeTopic.treeTopicData"
                    :search="search"
                    v-model="treeTopic.treeTopicDataModel"
                    item-children="Children"
                    activatable
                    multiple="false"
                    selection-type="leaf"
                    item-key="Id"
                    color="accent"
                    item-text="Title"
                    style="min-width: fit-content"
                    @update:active="onSelectedTopic"
                  >
                    <template v-slot:label="{ item }">
                      {{ item.Title.split(">")[0] }}
                      <v-icon v-if="item.Title.split('>')[1]" size="14"
                        >mdi-chevron-right</v-icon
                      >
                      {{ item.Title.split(">")[1] }}
                    </template>
                  </v-treeview>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="8">
              <v-container class="mx-0 col-12">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      :disabled="!isContentGroupSelected"
                      type="button"
                      @click="promptAddNewContent()"
                      color="success"
                      >{{ $t("_common.Create") }}
                    </v-btn>
                  </template>
                  <span>{{ $t("_create_content.Add_a_content") }}</span>
                </v-tooltip>
                <v-layout>
                  <v-flex xs12 class="my-2">
                    <v-card xs12 width="100%">
                      <v-data-table
                        :headers="headers"
                        :items="formItems"
                        ref="sortableTable"
                        item-key="Id"
                        height="300"
                        :no-data-text="this.$t('_common.No_data_available')"
                        :footer-props="{
                          'items-per-page-text': this.$t(
                            '_common.Rows_per_page'
                          ),
                        }"
                      >
                        <template v-slot:item.action="{ item }">
                          <feather-icon
                            icon="Trash2Icon"
                            svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                            @click="deleteContent(item)"
                          />
                          <feather-icon
                            icon="EditIcon"
                            svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                            @click="updateContent(item)"
                          />
                        </template>
                        <template v-slot:item.move="{ item }">
                          <v-btn style="cursor: move" icon class="sortHandle">
                            <v-icon>drag_handle</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.Type="{ item }">
                          <span>{{
                            item.Type == 1
                              ? "Video"
                              : item.Type == 2
                              ? "HTML"
                              : item.Type == 3
                              ? "Unreal"
                              : item.Type == 4
                              ? "Exam"
                              : "Pdf"
                          }}</span>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-col>
          </v-row>
        </v-card>
      </v-layout>
    </v-container>

    <v-dialog v-model="addNewContentModal" width="750">
      <v-form lazy-validation v-model="valid" ref="form">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title
            >{{ $t("_create_content.Add_content_Text") }}
          </v-card-title>

          <v-card xs12 class="content-add-edit-dialog">
            <v-card-text>
              <v-text-field
                solo
                v-model="nameModel"
                :label="this.$t('_create_content.Please_enter_a_name')"
                :rules="requiredRules"
              />

              <v-textarea
                v-model="descriptionModel"
                :label="this.$t('_create_content.Please_enter_a_description')"
                solo
              />

              <v-row>
                <!-- Video content button -->
                <v-col sm="2">
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      :elevation="hover ? 8 : 2"
                      class="mx-auto"
                      @click="onChangeContentType(1)"
                    >
                      <v-badge
                        v-if="contentTypeModel === 1"
                        color="green"
                        icon="mdi-check"
                      ></v-badge>
                      <v-img
                        :aspect-ratio="16 / 9"
                        src="/assets/add-content-images/video.jpg"
                      >
                        <v-avatar
                          v-if="hover"
                          color="white"
                          size="36"
                          class="centered"
                          style="
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                          "
                        >
                          <v-icon color="black">mdi-play</v-icon>
                        </v-avatar>
                        <v-avatar size="25"></v-avatar>
                      </v-img>
                      <v-card-text class="pt-6" style="position: relative">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <h5 class="truncate font-semibold" v-on="on">
                              {{ $t("_create_content.Video") }}
                            </h5>
                          </template>
                          <span>{{
                            $t(
                              "_create_content._video.Click_to_add_Video_Content"
                            )
                          }}</span>
                        </v-tooltip>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>

                <!-- html content button -->
                <v-col sm="2">
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      :elevation="hover ? 8 : 2"
                      class="mx-auto"
                      @click="onChangeContentType(2)"
                    >
                      <v-badge
                        v-if="contentTypeModel === 2"
                        color="green"
                        icon="mdi-check"
                      ></v-badge>
                      <v-img
                        :aspect-ratio="16 / 9"
                        src="/assets/add-content-images/html.jpg"
                      >
                        <v-avatar
                          v-if="hover"
                          color="white"
                          size="36"
                          class="centered"
                          style="
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                          "
                        >
                          <v-icon color="black">mdi-web</v-icon>
                        </v-avatar>
                        <v-avatar size="25"></v-avatar>
                      </v-img>
                      <v-card-text class="pt-6" style="position: relative">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <h5 class="truncate font-semibold" v-on="on">
                              {{ $t("_create_content.HTML") }}
                            </h5>
                          </template>
                          <span>{{
                            $t(
                              "_create_content._html.Click_to_add_Html_Content"
                            )
                          }}</span>
                        </v-tooltip>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>

                <!-- Unreal content button -->
                <v-col sm="2">
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      :elevation="hover ? 8 : 2"
                      class="mx-auto"
                      @click="onChangeContentType(3)"
                    >
                      <v-badge
                        v-if="contentTypeModel === 3"
                        color="green"
                        icon="mdi-check"
                      ></v-badge>
                      <v-img
                        :aspect-ratio="16 / 9"
                        src="/assets/add-content-images/unreal.jpg"
                      >
                        <v-avatar
                          v-if="hover"
                          color="white"
                          size="36"
                          class="centered"
                          style="
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                          "
                        >
                          <v-icon color="black">mdi-cube</v-icon>
                        </v-avatar>
                        <v-avatar size="25"></v-avatar>
                      </v-img>
                      <v-card-text class="pt-6" style="position: relative">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <h5 class="truncate font-semibold" v-on="on">
                              {{ $t("_create_content.Unreal") }}
                            </h5>
                          </template>
                          <span>{{
                            $t(
                              "_create_content._webgl.Click_to_add_WebGL_Content"
                            )
                          }}</span>
                        </v-tooltip>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>

                <!-- Exam content button -->
                <v-col sm="2">
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      :elevation="hover ? 8 : 2"
                      class="mx-auto"
                      @click="onChangeContentType(4)"
                    >
                      <v-badge
                        v-if="contentTypeModel === 4"
                        color="green"
                        icon="mdi-check"
                      ></v-badge>
                      <v-img
                        :aspect-ratio="16 / 9"
                        src="/assets/add-content-images/exam.jpg"
                      >
                        <v-avatar
                          v-if="hover"
                          color="white"
                          size="36"
                          class="centered"
                          style="
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                          "
                        >
                          <v-icon color="black">mdi-pencil</v-icon>
                        </v-avatar>
                        <v-avatar size="25"></v-avatar>
                      </v-img>
                      <v-card-text class="pt-6" style="position: relative">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <h5 class="truncate font-semibold" v-on="on">
                              {{ $t("_create_content.Exam") }}
                            </h5>
                          </template>
                          <span>{{
                            $t(
                              "_create_content._exam.Click_to_add_exam_Content"
                            )
                          }}</span>
                        </v-tooltip>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>

                <!-- Pdf content button -->
                <v-col sm="2">
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      :elevation="hover ? 8 : 2"
                      class="mx-auto"
                      @click="onChangeContentType(5)"
                    >
                      <v-badge
                        v-if="contentTypeModel === 5"
                        color="green"
                        icon="mdi-check"
                      ></v-badge>
                      <v-img
                        :aspect-ratio="16 / 9"
                        src="/assets/add-content-images/pdf.jpg"
                      >
                        <v-avatar
                          v-if="hover"
                          color="white"
                          size="36"
                          class="centered"
                          style="
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                          "
                        >
                          <v-icon color="black">mdi-book</v-icon>
                        </v-avatar>
                        <v-avatar size="25"></v-avatar>
                      </v-img>
                      <v-card-text class="pt-6" style="position: relative">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <h5 class="truncate font-semibold" v-on="on">
                              {{ $t("_create_content.Pdf") }}
                            </h5>
                          </template>
                          <span
                            >{{
                              $t(
                                "_create_content._pdf.Click_to_add_Pdf_Content"
                              )
                            }}
                          </span>
                        </v-tooltip>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>

                <!-- Drive content button -->
                <v-col sm="2" v-if="isDriveSupported">
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      :elevation="hover ? 8 : 2"
                      class="mx-auto"
                      @click="onChangeContentType(6)"
                    >
                      <v-badge
                        v-if="contentTypeModel === 6"
                        color="green"
                        icon="mdi-check"
                      ></v-badge>
                      <v-img
                        :aspect-ratio="16 / 9"
                        src="/assets/add-content-images/drive.jpg"
                      >
                        <v-avatar
                          v-if="hover"
                          color="white"
                          size="36"
                          class="centered"
                          style="
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                          "
                        >
                          <v-icon color="black">mdi-pencil</v-icon>
                        </v-avatar>
                        <v-avatar size="25"></v-avatar>
                      </v-img>
                      <v-card-text class="pt-6" style="position: relative">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <h5 class="truncate font-semibold" v-on="on">
                              {{ $t("_create_content.Drive") }}
                            </h5>
                          </template>
                          <span>{{
                            $t(
                              "_create_content._drive.Click_to_add_Drive_Content"
                            )
                          }}</span>
                        </v-tooltip>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <!-- ***********Video Content************ -->
                  <v-card v-if="seenFileContent" scrollable persistent>
                    <v-card>
                      <v-card-title>{{
                        $t("_create_content._video.Video_Content")
                      }}</v-card-title>
                      <v-divider></v-divider>
                      <v-card-text style="overflow: hidden">
                        <v-row>
                          <v-col cols="12" md="4" sm="12">
                            <v-checkbox
                              :label="
                                this.$t('_create_content._video.Is_this_text')
                              "
                              v-model="videoIs360Model"
                            />
                          </v-col>
                          <v-col cols="12" md="4" sm="12">
                            <v-checkbox
                              :label="
                                this.$t(
                                  '_create_content._video.Disable_Video_Controls'
                                )
                              "
                              v-model="mandatoryWatchModel"
                            />
                          </v-col>
                          <v-col cols="12" md="12" sm="12">
                            <v-file-input
                              :label="
                                this.$t(
                                  '_create_content._video.Please_select_a_video'
                                )
                              "
                              accept="video/*"
                              :success="successFile"
                              :error="errorFile"
                              v-model="files"
                              :truncate-length="fileInputTruncateLength"
                              @change="videoFileTypeCheck"
                              ref="videoFileUploader"
                            />
                          </v-col>
                          <v-col cols="12" md="12" sm="12">
                            <v-data-table
                              :headers="subtitleHeaders"
                              :items="subtitles"
                              sort-by="calories"
                              class="elevation-1"
                              :no-data-text="this.$t('_common.No_Data')"
                              :footer-props="{
                                'items-per-page-text': this.$t(
                                  '_common.Rows_per_page'
                                ),
                              }"
                            >
                              <template v-slot:top>
                                <v-toolbar flat color="white">
                                  <v-dialog
                                    v-model="subtitleDialog"
                                    max-width="500px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        color="primary"
                                        dark
                                        class="mb-2"
                                        v-on="on"
                                      >
                                        {{
                                          $t(
                                            "_create_content._video.Add_Subtitle"
                                          )
                                        }}
                                      </v-btn>
                                    </template>
                                    <v-card>
                                      <v-card-title>
                                        <span class="headline">{{
                                          formTitle
                                        }}</span>
                                      </v-card-title>

                                      <v-card-text>
                                        <v-container>
                                          <v-row>
                                            <v-col
                                              cols="12"
                                              sm="6"
                                              md="4"
                                              xm="6"
                                            >
                                              <v-text-field
                                                v-model="
                                                  subtitleEditedItem.language
                                                "
                                                label="Language"
                                              />
                                            </v-col>
                                            <v-col
                                              cols="12"
                                              sm="6"
                                              md="4"
                                              xm="6"
                                            >
                                              <v-file-input
                                                v-model="
                                                  subtitleEditedItem.file
                                                "
                                                :label="
                                                  getLangText(
                                                    '_create_content._video._add_subtitle.Please_Select'
                                                  )
                                                "
                                                accept=".srt"
                                                ref="subtitleFileUploader"
                                                :truncate-length="
                                                  fileInputTruncateLength
                                                "
                                                @change="subtitleFileTypeCheck"
                                              />
                                            </v-col>
                                          </v-row>
                                        </v-container>
                                      </v-card-text>

                                      <v-card-actions>
                                        <v-spacer />
                                        <v-btn
                                          color="blue darken-1"
                                          text
                                          @click="subtitleClose"
                                        >
                                          {{ $t("_common.Cancel") }}
                                        </v-btn>
                                        <v-btn
                                          color="blue darken-1"
                                          text
                                          @click="subtitleSave"
                                          >{{ $t("_common.Save") }}
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-dialog>
                                </v-toolbar>
                              </template>
                              <template v-slot:item.action="{ item }">
                                <!-- <v-icon small class="mr-2" @click="subtitleGridEditItem(item)">edit</v-icon> -->
                                <v-icon
                                  small
                                  class="mr-2"
                                  @click="subtitleGridDeleteItem(item)"
                                >
                                  {{ $t("_common.Delete") }}
                                </v-icon>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider></v-divider>
                      <!--<v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="seenFileContent = false" class="mr-2">Close</v-btn>
                      </v-card-actions>-->
                    </v-card>
                  </v-card>
                  <!-- ***********Html Content************ -->
                  <v-card v-if="seenHtmlContent" scrollable persistent>
                    <v-card>
                      <v-card-title>{{
                        $t("_create_content._html.Html_Content")
                      }}</v-card-title>
                      <v-divider></v-divider>
                      <v-card-text class="p-4" style="overflow: hidden">
                        <ckeditor
                          :editor="editor"
                          v-model="htmlContentModel"
                          :config="editorConfig"
                        ></ckeditor>
                      </v-card-text>
                      <v-divider></v-divider>
                      <!--<v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="seenHtmlContent=false" class="mr-2">Close</v-btn>
                      </v-card-actions>-->
                    </v-card>
                  </v-card>
                  <!-- ***********Unreal Content************ -->
                  <v-card v-if="seenUnrealContent" scrollable persistent>
                    <v-card>
                      <v-card-title>{{
                        $t("_create_content._webgl.WebGL_Content")
                      }}</v-card-title>
                      <v-divider></v-divider>
                      <v-card-text style="overflow: hidden">
                        <v-file-input
                          :label="
                            this.$t(
                              '_create_content._webgl.Please_select_a_zip_file'
                            )
                          "
                          accept="application/zip"
                          :success="successUnrealFile"
                          :error="errorUnrealFile"
                          v-model="unrealFiles"
                          ref="zipFileUploader"
                          :truncate-length="fileInputTruncateLength"
                          @change="zipFileTypeCheck"
                        />
                      </v-card-text>
                      <v-divider></v-divider>
                      <!--<v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="seenUnrealContent=false" class="mr-2">Close</v-btn>
                      </v-card-actions>-->
                    </v-card>
                  </v-card>
                  <!-- ***********Exam Content************ -->
                  <v-card v-if="seenExamContent" scrollable persistent>
                    <v-card>
                      <v-card-title>{{
                        $t("_create_content._exam.Exam_Content")
                      }}</v-card-title>
                      <v-divider></v-divider>
                      <v-card-text style="overflow: hidden">
                        <v-flex>
                          <v-autocomplete
                            :label="
                              this.$t(
                                '_create_content._exam.Select_an_exam_sheet'
                              )
                            "
                            v-model="examModel"
                            return-object
                            :items="examItems"
                            item-text="Title"
                            item-key="Id"
                          ></v-autocomplete>
                        </v-flex>
                      </v-card-text>
                      <v-divider></v-divider>
                      <!--<v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="seenExamContent=false" class="mr-2">Close</v-btn>
                      </v-card-actions>-->
                    </v-card>
                  </v-card>
                  <!-- ***********Pdf Content************ -->
                  <v-card v-if="seenPdfContent" scrollable persistent>
                    <v-card>
                      <v-card-title>{{
                        $t("_create_content._pdf.Pdf_Content")
                      }}</v-card-title>
                      <v-divider></v-divider>
                      <v-card-text style="overflow: hidden">
                        <v-file-input
                          :label="
                            this.$t('_create_content._pdf.Please_Select_Text')
                          "
                          accept="application/pdf"
                          :success="successPdfFile"
                          :error="errorPdfFile"
                          v-model="pdfFiles"
                          :truncate-length="fileInputTruncateLength"
                          @change="pdfFileTypeCheck"
                          ref="pdfFileUploader"
                        />
                      </v-card-text>
                      <v-divider></v-divider>
                      <!--<v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="seenPdfContent=false" class="mr-2">Close</v-btn>
                      </v-card-actions>-->
                    </v-card>
                  </v-card>
                  <!-- ***********XperDrive Content************ -->
                  <v-card v-if="seenDriveContent" scrollable persistent>
                    <v-card>
                      <v-card-title>{{
                        $t("_create_content._drive.Drive_Content")
                      }}</v-card-title>
                      <v-divider></v-divider>
                      <v-card-text style="overflow: hidden">
                        <v-flex>
                          <v-autocomplete
                            :label="
                              this.$t(
                                '_create_content._drive.Please_Select_Text'
                              )
                            "
                            v-model="driveModel"
                            return-object
                            :items="driveItems"
                            item-text="name"
                            item-key="id"
                          ></v-autocomplete>
                        </v-flex>
                      </v-card-text>
                      <v-divider></v-divider>
                      <!--<v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="seenExamContent=false" class="mr-2">Close</v-btn>
                      </v-card-actions>-->
                    </v-card>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn type="button" color="success" @click="addContent()"
              >{{ $t(contentId ? "_common.Update" : "_common.Create") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-card>
</template>

<script>
import Sortable from "sortablejs";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Table from "../ui-elements/table/Table";
import ENUMS from "../../goc/enums.js";
require("../../assets/css/neo.css");

export default {
  components: {
    Table,
    Sortable,
  },
  data() {
    return {
      isDriveSupported: Boolean(ENUMS.API.Drive),
      loading: false,
      progress: 0,
      fileInputTruncateLength: 200,
      editorConfig: {
        placeholder: this.$t(" "),
        language: this.$t("_common.LanguageOption"),
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "|",
          "blockQuote",
          "insertTable",
          "undo",
          "redo",
        ],
      },
      treeTopic: {
        treeTopicData: [],
        treeTopicDataModel: [],
      },
      contentTypeModel: null,
      contentTypeModelDialog: null,
      nameModel: null,
      descriptionModel: null,
      fileModel: null,
      addNewContentModal: false,
      successContentType: false,
      successFile: false,
      errorFile: false,
      successPdfFile: false,
      errorPdfFile: false,
      errorContentType: true,
      contentTypeData: [
        { text: this.$t("_create_content.Video"), value: 1 },
        { text: this.$t("_create_content.HTML"), value: 2 },
        { text: this.$t("_create_content.Unreal"), value: 3 },
        { text: this.$t("_create_content.Exam"), value: 4 },
      ],
      seenHtmlContent: false,
      seenFileContent: false,
      seenExamContent: false,
      seenPdfContent: false,
      seenDriveContent: false,
      successTreeTopic: false,
      seenUnrealContent: false,
      seenHtmlContentDialog: false,
      seenFileContentDialog: false,
      seenExamContentDialog: false,
      seenPdfContentDialog: false,
      seenDriveContentDialog: false,
      successTreeTopicDialog: false,
      seenUnrealContentDialog: false,
      isContentGroupSelected: false,
      htmlContentModel: "",
      dragNdrop: [],
      headers: [
        {
          text: this.$t("_create_content.Move"),
          sortable: false,
          value: "move",
        },
        {
          text: this.$t("_create_content.Name"),
          align: "left",
          sortable: false,
          value: "Name",
        },
        {
          text: this.$t("_create_content.Description"),
          align: "left",
          sortable: false,
          value: "Description",
        },
        {
          text: this.$t("_create_content.Content_Type"),
          align: "left",
          sortable: false,
          value: "Type",
        },
        {
          text: this.$t("_create_content.Actions"),
          align: "left",
          sortable: false,
          value: "action",
        },
      ],
      formItems: [],
      files: null,

      valid: false,
      requiredRules: this.$goc.validate.create().required(),
      subtitleEditedIndex: -1,
      videoIs360Model: null,
      mandatoryWatchModel: null,
      subtitleDialog: false,
      subtitleHeaders: [
        {
          text: this.$t("_create_content.Language"),
          align: "left",
          sortable: false,
          value: "language",
        },
        { text: this.$t("_create_content.File"), value: "fileName" },
        {
          text: this.$t("_create_content.Actions"),
          value: "action",
          sortable: false,
        },
      ],
      subtitles: [],
      subtitleEditedItem: {
        language: "",
        file: null,
        fileName: "",
      },
      subtitleDefaultItem: {
        language: "",
        file: null,
      },
      editor: ClassicEditor,
      unrealFiles: null,
      pdfFiles: null,
      successUnrealFile: null,
      errorUnrealFile: false,
      examItems: [],
      examModel: null,
      driveModel: null,
      driveItems: null,
      card_header_props: {
        header: {
          headLine: this.$t("_create_content.Add_New_Content"),
          subTitle: this.$t("_create_content.Create_topic_contents"),
          icon: "mdi-clipboard-text",
        },
        helper: {
          absolute: true,
          opacity: 0.8,
          overlay: false,
          page_helper: false,
        },
      },
      search: null,
      enum: {
        1: "Video",
        2: "Html",
        3: "Unreal",
        4: "Exam",
        5: "Pdf",
        6: "Drive",
      },
      contentId: null,
      contentPropertyId: null,
    };
  },
  methods: {
    onChangeContentType(value) {
      this.seenFileContent = value === 1;
      this.seenHtmlContent = value === 2;
      this.seenUnrealContent = value === 3;
      this.seenExamContent = value === 4;
      this.seenPdfContent = value === 5;
      this.seenDriveContent = value === 6;
      this.contentTypeModel = value;
    },
    onChangeContentTypeModal(value) {
      this.seenFileContentDialog = value === 1;
      this.seenHtmlContentDialog = value === 2;
      this.seenUnrealContentDialog = value === 3;
      this.seenExamContentDialog = value === 4;
      this.seenPdfContentDialog = value === 5;
      this.seenDriveContentDialog = value === 6;
      this.contentTypeModelDialog = value;
    },
    getLangText(_text) {
      return this.$goc.langHelper.getLangText(_text);
    },
    promptAddNewContent() {
      this.nameModel = null;
      this.descriptionModel = null;
      this.seenFileContent = false;
      this.seenHtmlContent = false;
      this.seenUnrealContent = false;
      this.seenExamContent = false;
      this.seenPdfContent = false;
      this.seenDriveContent = false;
      this.contentTypeModel = null;
      this.contentId = null;
      this.contentPropertyId = null;
      this.addNewContentModal = true;
    },
    onSelectedTopic(item) {
      if (item.length > 0) {
        this.successTreeTopic = true;
        this.refresh(item[0]);
      } else {
        this.successTreeTopic = false;
      }
      this.treeTopic.treeTopicDataModel = item;
      this.isContentGroupSelected = false;
      for (var i = 0; i < this.treeTopic.treeTopicData.length; i++) {
        let contentGroups = this.treeTopic.treeTopicData[i].Children;
        for (var ii = 0; ii < contentGroups.length; ii++) {
          if (contentGroups[ii].Id == item) {
            this.isContentGroupSelected = true;
            break;
          }
        }
      }
    },
    getTopicsForTeacher() {
      this.$goc.request.get(this.$enums.API.Topic, (response) => {
        let courses = response.Result.Topics;
        for (var i = 0; i < courses.length; i++) {
          let lessons = courses[i].Parent.Title;
          let modules = courses[i].Parent.Parent.Title;
          courses[i].Title =
            courses[i].Title + " (" + modules + ">" + lessons + ")";
          if (courses[i].Children.length > 0) {
            this.treeTopic.treeTopicData.push(courses[i]);
          }
        }
      });
    },
    getTopicsForAdmin() {
      this.$goc.request.get(this.$enums.API.Topic, (response) => {
        let modules = response.Result.Topics;
        for (var i = 0; i < modules.length; i++) {
          let lessons = modules[i].Children;
          for (var ii = 0; ii < lessons.length; ii++) {
            let courses = lessons[ii].Children;
            for (var iii = 0; iii < courses.length; iii++) {
              courses[iii].Title =
                courses[iii].Title +
                " (" +
                modules[i].Title +
                ">" +
                lessons[ii].Title +
                ")";
              if (courses[iii].Children.length > 0) {
                this.treeTopic.treeTopicData.push(courses[iii]);
              }
            }
          }
        }
      });
    },
    getTopics() {
      if (
        JSON.parse(localStorage.getItem("userInfo")).userRole == "LmsTeacher"
      ) {
        this.getTopicsForTeacher();
      } else if (
        JSON.parse(localStorage.getItem("userInfo")).userRole ==
        "LmsAdministrator"
      )
        this.getTopicsForAdmin();
    },
    getExams() {
      this.$goc.request.get(this.$enums.API.QuestionSheet, (response) => {
        this.examItems = response.Result.Sheets;
      });
    },
    getDriveContents() {
      this.$goc.request.get(this.$enums.API.Drive.Single, (response) => {
        this.driveItems = response.result;
      });
    },
    errorNotify(message) {
      this.$vs.notify({
        title: this.$t("_common.ThereWasProblem"),
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "danger",
      });
    },
    warningNotify(message) {
      this.$vs.notify({
        title: this.$t("_common.Warning"),
        text: message,
        iconPack: "feather",
        icon: "icon-warning-circle",
        color: "warning",
      });
    },
    successNotify(message) {
      this.$vs.notify({
        title: this.$t("_common.Success"),
        text: message,
        iconPack: "feather",
        icon: "icon-success-circle",
        color: "success",
      });
    },
    clearForm() {
      // this.$refs.form.reset();

      this.seenFileContent = false;
      this.seenHtmlContent = false;
      this.seenUnrealContent = false;
      this.seenExamContent = false;
      this.seenPdfContent = false;
      this.contentTypeModel = null;
      this.contentId = null;
      this.contentPropertyId = null;
    },
    addContent() {
      if (!this.$refs.form.validate()) return;
      if (!this.preCondition()) {
        return;
      }
      this.loading = true;
      if (this.contentTypeModel === 1) {
        this.createVideoContent();
        this.addNewContentModal = false;
      } else if (this.contentTypeModel === 2) {
        this.createHtmlContent();
        this.addNewContentModal = false;
      } else if (this.contentTypeModel === 3) {
        this.createUnrealContent();
        this.addNewContentModal = false;
      } else if (this.contentTypeModel === 4) {
        this.createExamContent();
        this.addNewContentModal = false;
      } else if (this.contentTypeModel === 5) {
        this.createPdfContent();
        this.addNewContentModal = false;
      } else if (this.contentTypeModel === 6) {
        this.createDriveContent();
        this.addNewContentModal = false;
      } else {
        this.$goc.notify.warning({
          title: this.$t("_create_content.Content_Type"),
          message: this.$t("_create_content.Please_select_Content_Type"),
        });
        this.loading = false;
      }
    },
    exception() {
      this.loading = false;
    },
    createVideoContent() {
      let formData = new FormData();
      let subTitlesForm = null;
      if (this.files) {
        formData.append("file", this.files);
        formData.append("isVideo360", this.videoIs360Model || false);
      }
      let _self = this;

      let contentSubtitles = [];
      let contentDto = {};

      const saveTotalForm = () => {
        const then = (response) => {
          this.loading = false;
          contentDto = {
            Name: _self.nameModel,
            Description: _self.descriptionModel,
            Topic: {
              Id: _self.treeTopic.treeTopicDataModel[0],
            },
            Type: _self.contentTypeModel,

            ContentProperty: {
              Url: response.result.url,
              ContentType: "video/mp4",
              VideoIs360: _self.videoIs360Model,
              MandatoryWatch: _self.mandatoryWatchModel,
              ContentSubtitles: contentSubtitles,
              CustomContent: JSON.stringify(response.result.children),
              Id: _self.contentPropertyId ? _self.contentPropertyId : 0,
            },
            Id: _self.contentId ? _self.contentId : 0,
          };

          if (contentDto.ContentProperty.VideoIs360 == null) {
            contentDto.ContentProperty.VideoIs360 = false;
          }
          if (contentDto.ContentProperty.MandatoryWatch == null) {
            contentDto.ContentProperty.MandatoryWatch = false;
          }
          _self.progress = 0;
          this.$goc.request.post(
            this.$enums.API.Content,
            contentDto,
            (response) => {
              if (response.IsSuccess) {
                this.successNotify(
                  this.$t("_create_content.Content_is_created")
                );
                this.refresh(_self.treeTopic.treeTopicDataModel[0]);
                this.clearForm();
              }
            }
          );
        };

        this.$goc.request.postVideo(
          this.$enums.API.FileUpload.Single,
          formData,
          {
            then,
            exception: this.exception,
          },
          {
            onUploadProgress: (progressEvent) => {
              _self.progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            },
          }
        );
      };

      let size = _self.subtitles.length - 1;
      let cnt = 0;
      if (size > -1) {
        for (const subtitle of _self.subtitles) {
          subTitlesForm = new FormData();
          subTitlesForm.append("file", subtitle.file);
          this.$goc.request.post(
            this.$enums.API.Videos.Single,
            subTitlesForm,
            (response) => {
              contentSubtitles.push({
                Kind: "subtitles",
                Language: subtitle.language,
                Url: response.result.url,
              });
              if (cnt === size) {
                saveTotalForm();
              }
              cnt++;
            }
          );
        }
      } else {
        saveTotalForm();
      }

      // return null;
    },
    createHtmlContent() {
      let contentDto = {
        Name: this.nameModel,
        Description: this.descriptionModel,
        Topic: {
          Id: this.treeTopic.treeTopicDataModel[0],
        },
        Type: this.contentTypeModel
          ? this.contentTypeModel
          : this.contentTypeModelDialog,
        ContentProperty: {
          HtmlContent: this.htmlContentModel,
          ContentType: "text/html",
          Id: this.contentPropertyId ? this.contentPropertyId : 0,
        },
        Id: this.contentId ? this.contentId : 0,
      };

      this.$goc.request.post(this.$enums.API.Content, contentDto, {
        then: (response) => {
          this.loading = false;
          if (response.IsSuccess === true) {
            this.successNotify(this.$t("_create_content.Content_is_created"));
            this.refresh(this.treeTopic.treeTopicDataModel[0]);
            this.htmlContentModel = "";
            this.clearForm();
          }
        },
      });
    },
    createUnrealContent() {
      let formData = new FormData();
      this.loading = true;
      if (this.unrealFiles) {
        formData.append("file", this.unrealFiles);
      }
      let _self = this;

      this.$goc.request.postMultipart(
        this.$enums.API.FileUpload.Unreal,
        formData,
        {
          then: (response) => {
            this.loading = false;
            let contentDto = {
              Name: _self.nameModel,
              Description: _self.descriptionModel,
              Topic: {
                Id: _self.treeTopic.treeTopicDataModel[0],
              },
              Type: _self.contentTypeModel,
              ContentProperty: {
                Url: response.result.url,
                ContentType: "application/html",
              },
              Id: _self.contentId ? _self.contentId : 0,
            };
            this.$goc.request.post(
              this.$enums.API.Content,
              contentDto,
              (response) => {
                if (response.IsSuccess === true) {
                  this.successNotify(
                    this.$t("_create_content.Content_is_created")
                  );
                  this.refresh(_self.treeTopic.treeTopicDataModel[0]);
                  this.clearForm();
                }
              }
            );
          },
          exception: () => {
            this.loading = false;
            this.$goc.notify.warning({
              title: this.$t("_common.Failed"),
              message: this.$t("_create_content._file_upload_error"),
            });
          },
        },
        {
          onUploadProgress: (progressEvent) => {
            _self.progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        }
      );
    },
    createPdfContent() {
      let formData = new FormData();
      if (this.pdfFiles) {
        formData.append("file", this.pdfFiles);
      }
      let _self = this;

      this.$goc.request.postMultipart(
        this.$enums.API.Pdf.Single,
        formData,
        {
          then: (response) => {
            this.loading = false;
            let contentDto = {
              Name: _self.nameModel,
              Description: _self.descriptionModel,
              Topic: {
                Id: _self.treeTopic.treeTopicDataModel[0],
              },
              Type: _self.contentTypeModel,
              ContentProperty: {
                Url: response.result.url,
                ContentType: "application/html",
                Id: this.contentPropertyId ? this.contentPropertyId : 0,
              },
              Id: _self.contentId ? _self.contentId : 0,
            };
            this.$goc.request.post(
              this.$enums.API.Content,
              contentDto,
              {
                then: (response) => {
                  if (response.IsSuccess === true) {
                    this.successNotify(
                      this.$t("_create_content.Content_is_created")
                    );
                    this.refresh(_self.treeTopic.treeTopicDataModel[0]);
                    this.clearForm();
                  }
                },
              },
              {
                onUploadProgress: (progressEvent) => {
                  _self.progress = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                },
              }
            );
          },
          exception: () => {
            this.loading = false;
            this.$goc.notify.warning({
              title: this.$t("_common.Failed"),
              message: this.$t("_create_content._file_upload_error"),
            });
          },
        },

        {
          onUploadProgress: (progressEvent) => {
            _self.progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        }
      );
    },
    createExamContent() {
      const _self = this;

      let contentDto = {
        Name: this.nameModel,
        Description: this.descriptionModel,
        Topic: {
          Id: this.treeTopic.treeTopicDataModel[0],
        },
        Type: this.contentTypeModel,
        ContentProperty: {
          CustomContent: JSON.stringify({
            ExamId: _self.examModel.Id,
          }),
          ContentType: "application/json",
          Id: _self.contentPropertyId ? _self.contentPropertyId : 0,
        },
        Id: _self.contentId ? _self.contentId : 0,
      };
      this.loading = true;
      this.$goc.request.post(this.$enums.API.Content, contentDto, {
        then: (response) => {
          this.loading = false;
          if (response.IsSuccess === true) {
            this.successNotify(this.$t("_create_content.Content_is_created"));
            this.refresh(_self.treeTopic.treeTopicDataModel[0]);
            this.clearForm();
          }
        },
      });
    },

    createDriveContent() {
      const _self = this;
      let driveUrl = "";

      //video path publicize ediliyor.
      this.$goc.request.get(
        `${this.$enums.API.Drive.MakeMyVideoToPublicize}?fileId=${_self.driveModel.id}`,
        {
          then: (response) => {
            driveUrl = response.result;
            let contentDto = {
              Name: this.nameModel,
              Description: this.descriptionModel,
              Topic: {
                Id: this.treeTopic.treeTopicDataModel[0],
              },
              Type: 1,

              ContentProperty: {
                Url: driveUrl,
                ContentType: "video/mp4",

                Id: _self.contentPropertyId ? _self.contentPropertyId : 0,
              },
              Id: _self.contentId ? _self.contentId : 0,
            };

            this.loading = true;
            this.$goc.request.post(this.$enums.API.Content, contentDto, {
              then: (response) => {
                this.loading = false;
                if (response.IsSuccess === true) {
                  this.successNotify(
                    this.$t("_create_content.Content_is_created")
                  );
                  this.refresh(_self.treeTopic.treeTopicDataModel[0]);
                  this.clearForm();
                }
              },
            });
          },
          exception: () => {
            this.loading = false;
          },
        }
      );
    },

    updateContent(item) {
      this.promptAddNewContent();
      this.contentId = item.Id;
      this.nameModel = item.Name;
      this.descriptionModel = item.Description;
      this.videoIs360Model = item.VideoIs360;
      this.contentTypeModel = item.Type;
      this.htmlContentModel = item.ContentProperty.HtmlContent;
      this.contentPropertyId = item.ContentProperty.Id;
      this.onChangeContentType(item.Type);
    },

    preCondition() {
      if (!this.successTreeTopic) {
        this.warningNotify(this.$t("_create_content.Please_select_a_topic"));
        return false;
      }
      if (this.contentTypeModel == null) {
        this.warningNotify(
          this.$t("_create_content.Please_select_content_type")
        );
        return false;
      }
      if (
        this.contentTypeModel === 2 &&
        (this.htmlContentModel == null || this.htmlContentModel.trim() === "")
      ) {
        this.warningNotify(
          this.$t("_create_content.Please_enter_HTML_content")
        );
        return false;
      }
      if (this.contentTypeModel === 1 && this.files == null) {
        this.warningNotify(this.$t("_create_content.Please_select_a_file"));
        return false;
      }
      return true;
    },
    preConditionDialog() {
      if (!this.successTreeTopic) {
        this.warningNotify(this.$t("_create_content.Please_select_a_topic"));
        return false;
      }
      if (this.contentTypeModelDialog == null) {
        this.warningNotify(
          this.$t("_create_content.Please_select_content_type")
        );
        return false;
      }
      if (
        this.contentTypeModelDialog === 2 &&
        (this.htmlContentModel == null || this.htmlContentModel.trim() === "")
      ) {
        this.warningNotify(
          this.$t("_create_content.Please_enter_HTML_content")
        );
        return false;
      }
      if (this.contentTypeModelDialog === 1 && this.files == null) {
        this.warningNotify(this.$t("_create_content.Please_select_a_file"));
        return false;
      }
      return true;
    },
    refresh(id) {
      if (!id) return;
      let _me = this;
      this.$goc.request.get(this.$enums.API.Content + id, (response) => {
        _me.formItems = response.Result.Contents;
        _me.$goc.console.log(response);
      });
    },
    dataTableSettings() {
      const table =
        this.$refs.sortableTable.$el.getElementsByTagName("tbody")[0];
      const _self = this;
      Sortable.create(table, {
        animation: 400,
        ghostClass: "blue-background-class",
        handle: ".sortHandle",
        onEnd({ oldIndex, newIndex }) {
          if (oldIndex === newIndex) return;
          let changeDisplayOrderRequest = {
            NewOrder: newIndex + 1,
            OldOrder: oldIndex + 1,
            TopicId: _self.treeTopic.treeTopicDataModel[0],
          };

          _self.$goc.request.post(
            _self.$enums.API.Content_Reorder,
            changeDisplayOrderRequest,
            (response) => {
              if (response.status === 200) {
                _self.successNotify(this.$t("_create_content.Reordered"));
                _self.formItems.splice(
                  newIndex,
                  0,
                  _self.formItems.splice(oldIndex, 1)[0]
                );
              }
            }
          );
        },
      });
    },
    deleteContent(item) {
      let me = this;
      this.$goc.request.delete(
        this.$enums.API.Content + item.Id,
        (response) => {
          me.$goc.notify.success({ message: response.Result.Status.Detail });
          me.refresh(me.treeTopic.treeTopicDataModel[0]);
        }
      );
    },

    subtitleGridEditItem(item) {
      this.subtitleEditedIndex = this.subtitles.indexOf(item);
      this.subtitleEditedItem = item;
      this.subtitleDialog = true;
    },
    subtitleGridDeleteItem(item) {
      let index = this.subtitles.indexOf(item);
      this.subtitles.splice(index, 1);
    },
    subtitleClose() {
      this.subtitleDialog = false;
      setTimeout(() => {
        this.subtitleEditedItem = Object.assign({}, this.subtitleDefaultItem);
        //this.subtitleEditedIndex = -1;
      }, 300);
    },
    subtitleSave() {
      if (this.subtitleEditedIndex === -1) {
        let subtitleEditedItem = {
          language: this.subtitleEditedItem.language,
          file: this.subtitleEditedItem.file,
          fileName: this.subtitleEditedItem.file.name,
        };
        this.subtitles.push(subtitleEditedItem);
      } else {
        this.subtitles[this.subtitleEditedIndex] = {
          language: this.subtitleEditedItem.language,
          file: this.subtitleEditedItem.file,
          fileName: this.subtitleEditedItem.file.name,
        };
        this.subtitleEditedIndex = -1;
        /*
          let formData = new FormData();
          if (this.subtitles) {
            formData.append("file", this.subtitleEditedItem.file);
          }
          var language = this.subtitleEditedItem.language;
          this.subtitleEditedItem.fileName = this.subtitleEditedItem.file.name;

          this.$goc.request.post(this.$enums.API.Videos.Single, formData, (response) => {
            let subtitleEditedItem = {
              language: language,
              file: response.result,
              fileName: response.result.url
            };
            this.subtitles.push(subtitleEditedItem);
          });
          */
      }
      this.subtitleClose();
    },

    videoFileTypeCheck(file) {
      var fileFormat = file ? file.type.split("/") : null;
      if (fileFormat && fileFormat[0] != "video") {
        this.files = null;
        this.$refs.videoFileUploader.reset();
        this.errorFile = true;
      } else {
        this.errorFile = false;
      }
    },
    subtitleFileTypeCheck(file) {
      var fileExtension = file ? file.name.split(".").pop() : null;
      if (fileExtension && fileExtension != "srt") {
        this.files = null;
        this.$refs.subtitleFileUploader.reset();
      }
    },
    zipFileTypeCheck(file) {
      var fileExtension = this.unrealFiles
        ? this.unrealFiles.name.split(".").pop()
        : null;
      if (fileExtension && fileExtension != "zip") {
        this.files = null;
        this.$refs.zipFileUploader.reset();
        this.errorUnrealFile = true;
      } else {
        this.errorUnrealFile = false;
      }
    },
    pdfFileTypeCheck() {
      var fileExtension = this.pdfFiles
        ? this.pdfFiles.name.split(".").pop()
        : null;
      if (fileExtension && fileExtension != "pdf") {
        this.files = null;
        this.$refs.pdfFileUploader.reset();
        this.errorPdfFile = true;
      } else {
        this.errorPdfFile = false;
      }
    },
  },

  mounted: function () {
    this.getTopics();
    this.dataTableSettings();
    this.$goc.setModule("AddContent", this);
  },
  computed: {
    progressText() {
      return this.progress < 100 ? this.progress : "loading...";
    },
    formTitle() {
      return this.subtitleEditedIndex === -1
        ? this.$t("_create_content.New_Subtitle")
        : this.$t("_create_content.Edit_Subtitle");
    },
    indexedItems() {
      return this.formItems.map((item, index) => ({
        index: index,
        ...item,
      }));
    },
  },
  watch: {
    subtitleDialog(val) {
      val || this.subtitleClose();
    },
    seenExamContent(val) {
      val && this.getExams();
    },
    seenDriveContent(val) {
      val && this.getDriveContents();
    },
  },
};
</script>

<style lang="scss">
button.btn-async {
  background: rgba(var(--vs-warning), 0.15);
}

.scroll-area {
  position: relative;
  margin: auto;
  width: auto;
  height: 18rem;
}

button.btn-delete {
  background: rgba(var(--vs-danger), 0.15);
}

.ck-editor__editable {
  max-height: 400px !important;
}

.ck.ck-reset.ck-editor.ck-rounded-corners {
  width: 100% !important;
}
</style>
